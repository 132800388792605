import React, { useRef, useEffect, useState, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';

import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import CastingForm from 'components/CastingForm';
import { CompanyNameContext } from 'components/CompanyNameContext';
import { TemporaryProfileUpdateCodeHasExpiredMessage } from 'components/SessionHadEndedMessage';

import RegistrationSuccess from 'containers/RegistrationSuccess';

import { getActor, updateActor } from 'actions/profileUpdate';
import {
  upload,
  crop,
  sortOrder,
  setDefault,
  remove,
  addVideo,
  updateVideo
} from 'actions/profileUpdateAttachmentProcessing';

import { startLoadingBar, endLoadingBar } from 'utils/loadingBar';
import { filesUploadValidationTypes, defaultFilesLimit } from 'utils/validation';

import { useErrorHandler } from 'hooks/useCompanyNameAndLogo';

import './Updation.scss';

const Updation = () => {
  const { apiKey, embed } = useParams();

  const loadingBarRef = useRef();
  const recaptchaRef = useRef();

  const location = useLocation();

  const [actor, setActor] = useState(location.state?.actor);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [decodedApiKey, setDecodedApiKey] = useState(decodeURIComponent(apiKey));
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [isCropEnabled, setIsCropEnabled] = useState(true);

  const { setCompanyNameAndLogo } = useContext(CompanyNameContext);

  const { isExpired, handleError } = useErrorHandler();

  useEffect(() => {
    const init = async () => {
      startLoadingBar(loadingBarRef.current);

      try {
        const decodedApiKey = decodeURIComponent(apiKey);
        setDecodedApiKey(decodedApiKey);
        const response = await getActor(decodedApiKey);
        response.actor.attachments = response.actor.attachments.map((attachment) => ({
          ...attachment,
          imageUrl: attachment.originalImageUri,
          thumbnailUrl: attachment.thumbnailImageUri,
          videoUrl: attachment.fileName
        }));
        setActor(response.actor);
        setCompanyNameAndLogo(response.companyDetials.companyName, response.companyDetials.companyLogoUrl);
        setIsCropEnabled(response.companyDetials.settings.cropEnabledForExternalRegistration);
      } catch (error) {
        handleError(error);
      } finally {
        endLoadingBar(loadingBarRef.current);
      }
    };

    if (!location.state?.actor) init();
  }, [apiKey, setCompanyNameAndLogo, location]);

  const onSubmit = async (values) => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    startLoadingBar(loadingBarRef.current);

    try {
      await updateActor(decodedApiKey, values);
      setIsUpdateSuccess(true);
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error.message);
    } finally {
      endLoadingBar(loadingBarRef.current);
      setIsSubmitting(false);
    }
  };

  const onPhotoSelect =
    (validationId) =>
    async (attachments = [], isFileImage = () => {}) => {
      if (!attachments.length && validationId !== 0) {
        toast.error(filesUploadValidationTypes[validationId].limitMessage);
        return [];
      } else if (!attachments.length && validationId === 0) {
        toast.error(defaultFilesLimit.limitMessage);
        return [];
      }

      startLoadingBar(loadingBarRef.current);

      const uploadedFiles = [];
      await Promise.all(
        attachments.map(async (file) => {
          try {
            const response = await upload(decodedApiKey, file, isCropEnabled);
            uploadedFiles.push({
              ...response,
              imageUrl: response.originalImageUri,
              thumbnailUrl: response.thumbnailImageUri,
              isPhoto: isFileImage(file)
            });
          } catch (ex) {
            const error = typeof ex === 'string' ? ex : ex.message;
            if (error.startsWith('You can upload up to') && validationId !== 0)
              toast.error(filesUploadValidationTypes[validationId].limitMessage);

            toast.error(error);
          }
        })
      );

      endLoadingBar(loadingBarRef.current);

      return uploadedFiles;
    };

  const onImageCrop = async (request) => {
    startLoadingBar(loadingBarRef.current);
    let response;
    try {
      response = await crop(decodedApiKey, request);
      response.imageUrl = response.originalImageUri;
      response.thumbnailUrl = response.thumbnailImageUri;

      return response;
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error.message);
    } finally {
      endLoadingBar(loadingBarRef.current);
    }
  };

  const onUpdateSortOrder = async (body, isThumbnailUpdate) => {
    startLoadingBar(loadingBarRef.current);
    try {
      await sortOrder(
        decodedApiKey,
        body.map(({ attachmentId, sortOrder }) => ({ actorAttachmentId: attachmentId, sortOrder }))
      );

      if (isThumbnailUpdate) await setDefault(decodedApiKey, body[0].attachmentId);
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error.message);
    } finally {
      endLoadingBar(loadingBarRef.current);
    }
  };

  const onDelete = async (attachmentId) => {
    startLoadingBar(loadingBarRef.current);
    try {
      await remove(decodedApiKey, attachmentId);
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error.message);
    } finally {
      endLoadingBar(loadingBarRef.current);
    }
  };

  const onVideoAdd = async (videoUrl) => {
    startLoadingBar(loadingBarRef.current);
    try {
      const response = await addVideo(decodedApiKey, videoUrl);
      return { ...response, videoUrl: response.fileName };
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error.message);
    } finally {
      endLoadingBar(loadingBarRef.current);
    }
  };

  const onVideoEdit = async (attachmentId, videoUrl) => {
    startLoadingBar(loadingBarRef.current);
    try {
      const response = await updateVideo(decodedApiKey, attachmentId, videoUrl);
      return { ...response, videoUrl: response.fileName };
    } catch (error) {
      toast.error(typeof error === 'string' ? error : error.message);
    } finally {
      endLoadingBar(loadingBarRef.current);
    }
  };

  const reCaptchaChanged = (token) => {};

  return (
    <>
      <div className="register">
        <Panel noBorder={embed === 'embed'}>
          {isExpired ? (
            <TemporaryProfileUpdateCodeHasExpiredMessage />
          ) : isUpdateSuccess ? (
            <RegistrationSuccess profileUpdate={true}>
              <h1 className='profile-update'>Your profile has been updated!</h1>
            </RegistrationSuccess>
          ) : (
            actor && (
              <CastingForm
                castingFormData={actor}
                onSubmit={onSubmit}
                onPhotoSelect={onPhotoSelect}
                onImageCrop={onImageCrop}
                onUpdateSortOrder={onUpdateSortOrder}
                onDelete={onDelete}
                recaptchaRef={recaptchaRef}
                reCaptchaChanged={reCaptchaChanged}
                onVideoAdd={onVideoAdd}
                onVideoEdit={onVideoEdit}
              />
            )
          )}
        </Panel>
      </div>
      <AppLoadingBar ref={loadingBarRef} />
    </>
  );
};

export default Updation;
