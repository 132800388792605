import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';

import Panel from 'components/Panel';
import AppLoadingBar from 'components/AppLoadingBar';
import SessionHadEndedMessage from 'components/SessionHadEndedMessage';

import { useCompanyNameAndLogo } from 'hooks/useCompanyNameAndLogo';

const VerifyCode = () => {
  const loadingBarRef = useRef();

  const { profileUpdateQuery } = useParams();

  const { isExpired } = useCompanyNameAndLogo(loadingBarRef, decodeURIComponent(profileUpdateQuery));
  Yup.object().shape({
    Code: Yup.string().required('Check Your Email')
  });
  return (
    <div className="welcome">
      <Panel>
        <div className="welcome-content">
          {isExpired ? (
            <SessionHadEndedMessage />
          ) : (
              <div className="welcome-introduction">
                <h1>Check Your Email</h1>
                <p>We've sent a temporary login link to your email. Please check your inbox (and spam folder just in case). Click the link to log in securely.</p>
              </div>
          )}
        </div>
      </Panel>
      <AppLoadingBar ref={loadingBarRef} />
    </div>
  );
};

export default VerifyCode;
