import React, { useState, forwardRef } from 'react';
import { Formik, Form, Field } from 'formik';
import objectPath from 'object-path';
import classnames from 'classnames';

import FormikEffect from 'components/FormikEffect';
import ReCaptcha from 'components/ReCaptcha';
import Button from 'components/Button';
import ErrorList from 'components/ErrorList';
import Input from 'components/Input';
import Dropdown from 'components/Dropdown';
import FileUpload from 'components/FileUpload';
import VideoUpload from 'components/VideoUpload';
import Icon from 'components/Icon';
import CheckboxMulti from 'components/CheckboxMulti';
import Checkbox from 'components/Checkbox';
import DateInputDropdown from 'components/DateInputDropdown';
import PhoneInput from 'components/PhoneInput';
import NumericInput from 'components/NumericInput';
import Textarea from 'components/Textarea';

import {
  FOOT_PLACEHOLDER,
  INCHES_PLACEHOLDER,
  CENTIMETERS_PLACEHOLDER,
  POUNDS_PLACEHOLDER,
  KILOGRAMS_PLACEHOLDER,
  SQUARE_FEET_PLACEHOLDER,
  SQUARE_METERS_PLACEHOLDER
} from 'utils/constants';
import { defaultFilesLimit, filesUploadValidationTypes } from 'utils/validation';
import * as opertationMode from 'utils/constants/operationMode';
import * as measuringSystem from 'utils/constants/measuringSystem';
import * as fileGroup from 'utils/constants/fileGroup';
import * as uploadValidationType from 'utils/constants/uploadValidationType';

import { buildCastingFormValidationSchema } from './validationUtils';
import {
  FIELD_FIRST_NAME_BIDINGFIELD,
  FIELD_LAST_NAME_BIDINGFIELD,
  MAX_TEXT_LENGTH_FORBIDINGFIELDS,
  MAX_TEXT_LENGTH
} from './consts';

const CastingForm = forwardRef(
  (
    {
      castingFormData,
      onSubmit,
      onPhotoSelect,
      onImageCrop,
      onUpdateSortOrder,
      recaptchaRef,
      reCaptchaChanged,
      onVideoAdd,
      onVideoEdit,
      onDelete,
      showEmailDuplicationMessage
    },
    formik
  ) => {
    const { staticText } = castingFormData;
    const items = [];

    const [showValidation, setShowValidation] = useState(false);

    const handleUnload = (e) => {
      // Custom message supported only on very old browsers, for new browsers non empty string just activates their own message
      const message = 'Changes you made are not saved. Are you sure you want to leave?';
      (e || window.event).returnValue = message; //Gecko + IE
      return message;
    };

    const removeUnloadHandle = () => {
      window.onbeforeunload = null;
    };

    const adjustUnloadHandle = () => {
      if (formik?.current?.dirty) {
        window.onbeforeunload = handleUnload;
      } else {
        removeUnloadHandle();
      }
    };

    const renderPhotoUpload = (fieldNameRoot, setFieldValue, errors, isPreview) => {
      const attribute = objectPath.get(castingFormData, fieldNameRoot);
      const hasError = objectPath.get(errors, `${fieldNameRoot}.isPhotoUploaded`);
      const validationType = filesUploadValidationTypes[attribute.validation];

      const files = castingFormData.attachments?.filter((file) => {
        switch (attribute.validation) {
          case uploadValidationType.IMAGE:
            return file.fileGroup === fileGroup.IMAGE;
          case uploadValidationType.VIDEO:
            return file.fileGroup === fileGroup.VIDEO_ATTACHMENT;
          case uploadValidationType.AUDIO:
            return file.fileGroup === fileGroup.AUDIO;
          case uploadValidationType.DOCUMENT:
            return file.fileGroup === fileGroup.DOCUMENT;
          default:
            return file.fileGroup !== fileGroup.VIDEO;
        }
      });

      return (
        <div className="photo-upload-group">
          <h2>{attribute.name}</h2>
          <p
            className={classnames('photo-header', {
              error: hasError
            })}
          >
            {attribute.description}
          </p>

          <FileUpload
            name="photos"
            files={files}
            accept={validationType?.accept || ''}
            filesValidation={validationType?.validate || defaultFilesLimit.validate}
            validationName={`${fieldNameRoot}.isPhotoUploaded`}
            onSelect={onPhotoSelect(attribute.validation)}
            setFieldValue={setFieldValue}
            isPreview={isPreview}
            onImageCrop={onImageCrop}
            onUpdateSortOrder={onUpdateSortOrder}
            onImageDelete={onDelete}
          />
        </div>
      );
    };

    const renderVideoUpload = (fieldNameRoot, setFieldValue, errors) => {
      const attribute = objectPath.get(castingFormData, fieldNameRoot);
      const hasError = objectPath.get(errors, `${fieldNameRoot}.isVideoUploaded`);

      return (
        <div className="video-upload-group">
          <h2>{attribute.name}</h2>
          <p
            className={classnames('video-header', {
              error: hasError
            })}
          >
            {attribute.description}
          </p>
          <VideoUpload
            name="Videos"
            validationName={`${fieldNameRoot}.isVideoUploaded`}
            placeholder="Vimeo, YouTube"
            buttonText={attribute.buttonText}
            setFieldValue={setFieldValue}
            onChange={onVideoAdd}
            videos={castingFormData.attachments?.filter((attachment) => attachment.fileGroup === fileGroup.VIDEO)}
            onEdit={onVideoEdit}
            onDelete={onDelete}
            description={attribute.description}
          />
        </div>
      );
    };

    const renderMeasurementHeightInput = (attribute, fieldNameRoot, errors) => {
      if (castingFormData.measuringSystem === measuringSystem.IMPERIAL) {
        return (
          <div className="joined-inputs">
            <Field
              component={NumericInput}
              key={`${fieldNameRoot}.measurementHeight.foot`}
              name={`${fieldNameRoot}.measurementHeight.foot`}
              label={attribute.name}
              placeholder={FOOT_PLACEHOLDER}
              parentFieldName={`${fieldNameRoot}.measurementHeight.foot`}
              error={
                objectPath.get(errors, `${fieldNameRoot}.measurementHeight.foot`) ||
                objectPath.get(errors, `${fieldNameRoot}.measurementHeight`)
              }
            />
            <Field
              component={NumericInput}
              key={`${fieldNameRoot}.measurementHeight.inches`}
              name={`${fieldNameRoot}.measurementHeight.inches`}
              placeholder={INCHES_PLACEHOLDER}
              parentFieldName={`${fieldNameRoot}.measurementHeight.inches`}
              error={
                objectPath.get(errors, `${fieldNameRoot}.measurementHeight.inches`) ||
                objectPath.get(errors, `${fieldNameRoot}.measurementHeight`)
              }
            />
          </div>
        );
      } else {
        return (
          <Field
            component={NumericInput}
            key={`${fieldNameRoot}.measurementHeight.cm`}
            name={`${fieldNameRoot}.measurementHeight.cm`}
            label={attribute.name}
            parentFieldName={`${fieldNameRoot}.measurementHeight`}
            placeholder={CENTIMETERS_PLACEHOLDER}
            error={
              objectPath.get(errors, `${fieldNameRoot}.measurementHeight.cm`) ||
              objectPath.get(errors, `${fieldNameRoot}.measurementHeight`)
            }
          />
        );
      }
    };

    const renderMeasurementWeightInput = (attribute, fieldNameRoot, errors) => {
      const fieldName =
        castingFormData.measuringSystem === measuringSystem.IMPERIAL
          ? `${fieldNameRoot}.measurementWeight.lb`
          : `${fieldNameRoot}.measurementWeight.kg`;

      return (
        <Field
          component={NumericInput}
          key={fieldName}
          name={fieldName}
          label={attribute.name}
          parentFieldName={`${fieldNameRoot}.measurementWeight`}
          placeholder={
            castingFormData.measuringSystem === measuringSystem.IMPERIAL ? POUNDS_PLACEHOLDER : KILOGRAMS_PLACEHOLDER
          }
          error={objectPath.get(errors, fieldName) || objectPath.get(errors, `${fieldNameRoot}.measurementWeight`)}
        />
      );
    };

    const renderMeasurementSimpleInput = (attribute, fieldNameRoot, errors) => {
      return (
        <Field
          component={NumericInput}
          key={`${fieldNameRoot}.measurementSimple.value`}
          name={`${fieldNameRoot}.measurementSimple.value`}
          label={attribute.name}
          step={0.01}
          min={0}
          placeholder={attribute.name}
          parentFieldName={`${fieldNameRoot}.measurementSimple`}
          error={
            objectPath.get(errors, `${fieldNameRoot}.measurementSimple.value`) ||
            objectPath.get(errors, `${fieldNameRoot}.measurementSimple`)
          }
        />
      );
    };

    const renderMeasurementAreaInput = (attribute, fieldNameRoot, errors) => {
      const fieldName =
        castingFormData.measuringSystem === measuringSystem.IMPERIAL
          ? `${fieldNameRoot}.measurementArea.squareFeet`
          : `${fieldNameRoot}.measurementArea.squareMeters`;
      return (
        <Field
          component={NumericInput}
          key={fieldName}
          name={fieldName}
          label={attribute.name}
          parentFieldName={`${fieldNameRoot}.measurementArea`}
          placeholder={
            castingFormData.measuringSystem === measuringSystem.IMPERIAL
              ? SQUARE_FEET_PLACEHOLDER
              : SQUARE_METERS_PLACEHOLDER
          }
          error={objectPath.get(errors, fieldName) || objectPath.get(errors, `${fieldNameRoot}.measurementArea`)}
        />
      );
    };

    const renderEmailDuplicationPermitCheckbox = () => {
      if (castingFormData.showDuplicationConsentBeforeSubmitting && showEmailDuplicationMessage) {
        return (
          <div className="welcome-tc-container casting-validation">
            <Checkbox onChange={tcAcceptEmailDuplicationTermsChanged} />
            <div className="tc-error">
              <span>{castingFormData.duplicateConsentMessage}</span>
            </div>
          </div>
        );
      }
    };

    const tcAcceptEmailDuplicationTermsChanged = (event) => {
      castingFormData.emailDuplicationPermited = event.target.checked;
    };

    const renderInput = (attribute, groupIndex, attributeIndex, setFieldValue, values, errors, items) => {
      const fieldNameRoot = `groups.${groupIndex}.attributes.${attributeIndex}`;
      let hasError = false;

      switch (attribute.controlType) {
        case opertationMode.TEXT_BOX: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.textValue`);
          break;
        }
        case opertationMode.DROP_DOWN: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.selectedItemId`);
          break;
        }
        case opertationMode.DROP_DOWN_MULTIPLE: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.selectedItemId`);
          break;
        }
        case opertationMode.CHECK_BOX:
        case opertationMode.CHECK_BOX_MULTIPLE: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.lookups`);
          break;
        }
        case opertationMode.DATE_OF_BIRTH: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.textValue`);
          break;
        }
        case opertationMode.PHONE_NUMBER: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.textValue`);
          break;
        }
        case opertationMode.EMAIL: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.textValue`);
          break;
        }
        case opertationMode.COMMENTS: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.textValue`);
          break;
        }
        case opertationMode.MEASUREMENT_HEIGHT: {
          if (castingFormData.measuringSystem === measuringSystem.IMPERIAL) {
            hasError =
              objectPath.get(errors, `${fieldNameRoot}.measurementHeight.foot`) ||
              objectPath.get(errors, `${fieldNameRoot}.measurementHeight`);
          } else {
            hasError =
              objectPath.get(errors, `${fieldNameRoot}.measurementHeight.cm`) ||
              objectPath.get(errors, `${fieldNameRoot}.measurementHeight`);
          }

          break;
        }
        case opertationMode.MEASUREMENT_WEIGHT: {
          const fieldName =
            castingFormData.measuringSystem === measuringSystem.IMPERIAL
              ? `${fieldNameRoot}.measurementWeight.lb`
              : `${fieldNameRoot}.measurementWeight.kg`;

          hasError = objectPath.get(errors, fieldName) || objectPath.get(errors, `${fieldNameRoot}.measurementWeight`);
          break;
        }
        case opertationMode.MEASUREMENT_SIMPLE: {
          hasError =
            objectPath.get(errors, `${fieldNameRoot}.measurementSimple.value`) ||
            objectPath.get(errors, `${fieldNameRoot}.measurementSimple`);
          break;
        }
        case opertationMode.MEASUREMENT_AREA: {
          const fieldName =
            castingFormData.measuringSystem === measuringSystem.IMPERIAL
              ? `${fieldNameRoot}.measurementArea.squareFeet`
              : `${fieldNameRoot}.measurementArea.squareMeters`;

          hasError = objectPath.get(errors, fieldName) || objectPath.get(errors, `${fieldNameRoot}.measurementArea`);
          break;
        }
        case opertationMode.LOCATION: {
          hasError = objectPath.get(errors, `${fieldNameRoot}.textValue`);
          break;
        }
        default: {
          break;
        }
      }

      if (attribute.isPhotos) {
        hasError = objectPath.get(errors, `${fieldNameRoot}.isPhotoUploaded`);
      }
      if (attribute.isVideo) {
        hasError = objectPath.get(errors, `${fieldNameRoot}.isVideoUploaded`);
      }

      let containsError = false;

      var errorMessage = `${attribute.name}`;
      if (hasError) {
        for (let i = 0; i < items.length; i++) {
          if (items[i] == errorMessage) {
            containsError = true;
            break;
          }
        }
        if (!containsError) {
          items.push(errorMessage);
        }
      } else {
        let errorItemItenx = -1;

        for (var i = 0; i < items.length; i++) {
          if (items[i] == errorMessage) {
            errorItemItenx = i;
            break;
          }
        }
        if (errorItemItenx > -1) {
          items.splice(errorItemItenx, 1);
        }
      }

      if (attribute.isPhotos) {
        return renderPhotoUpload(fieldNameRoot, setFieldValue, errors);
      }

      if (attribute.isVideo) {
        return renderVideoUpload(fieldNameRoot, setFieldValue, errors);
      }

      switch (attribute.controlType) {
        case opertationMode.TEXT_BOX:
          return (
            <Field
              component={Input}
              key={`${fieldNameRoot}.textValue`}
              name={`${fieldNameRoot}.textValue`}
              label={attribute.name}
              placeholder={attribute.name}
              setFieldValue={setFieldValue}
              onChange={(e) => {
                if (
                  attribute.bindingField == FIELD_FIRST_NAME_BIDINGFIELD ||
                  attribute.bindingField == FIELD_LAST_NAME_BIDINGFIELD
                ) {
                  if (e.target.value.length > MAX_TEXT_LENGTH_FORBIDINGFIELDS) {
                    e.target.value = e.target.value.substring(0, MAX_TEXT_LENGTH_FORBIDINGFIELDS);
                    setFieldValue(`${fieldNameRoot}.textValue`, e.target.value);
                  }
                }
                if (e.target.value.length > MAX_TEXT_LENGTH) {
                  e.target.value = e.target.value.substring(0, MAX_TEXT_LENGTH);
                  setFieldValue(`${fieldNameRoot}.textValue`, e.target.value);
                }
              }}
              error={objectPath.get(errors, `${fieldNameRoot}.textValue`)}
            />
          );
        case opertationMode.DROP_DOWN:
          return (
            <Field
              component={Dropdown}
              key={`${fieldNameRoot}.selectedItemId`}
              name={`${fieldNameRoot}.selectedItemId`}
              label={attribute.name}
              setFieldValue={setFieldValue}
              error={objectPath.get(errors, `${fieldNameRoot}.selectedItemId`)}
            >
              {attribute.lookups &&
                attribute.lookups.map((lookup) => (
                  <option key={lookup.id} value={lookup.id}>
                    {lookup.name}
                  </option>
                ))}
            </Field>
          );
        case opertationMode.DROP_DOWN_MULTIPLE:
          return (
            <div className="dropdown-multi-input">
              <Field
                component={Dropdown}
                key={`${fieldNameRoot}.selectedItemId`}
                name={`${fieldNameRoot}.selectedItemId`}
                label={attribute.name}
                setFieldValue={setFieldValue}
                error={objectPath.get(errors, `${fieldNameRoot}.selectedItemId`)}
                additionalControl={
                  <Icon
                    icon="plus"
                    onClick={() => {
                      const newAttributes = values.groups[groupIndex].attributes;
                      const newAttribute = {
                        ...values.groups[groupIndex].attributes[attributeIndex],
                        selectedItemId: ''
                      };
                      newAttributes.splice(attributeIndex + 1, 0, newAttribute);

                      setFieldValue(`groups.${groupIndex}.attributes`, newAttributes);
                    }}
                  />
                }
              >
                {attribute.lookups &&
                  attribute.lookups.map((lookup) => (
                    <option key={lookup.id} value={lookup.id}>
                      {lookup.name}
                    </option>
                  ))}
              </Field>
            </div>
          );
        case opertationMode.CHECK_BOX:
          return (
            <div className="checkbox-multi-input">
              <Field
                component={CheckboxMulti}
                key={`${fieldNameRoot}.lookups`}
                name={`${fieldNameRoot}.lookups`}
                items={attribute.lookups}
                label={attribute.name}
                setFieldValue={setFieldValue}
                error={objectPath.get(errors, `${fieldNameRoot}.lookups`)}
                singleCheckBox={true}
              ></Field>
            </div>
          );
        case opertationMode.CHECK_BOX_MULTIPLE:
          return (
            <div className="checkbox-multi-input">
              <Field
                component={CheckboxMulti}
                key={`${fieldNameRoot}.lookups`}
                name={`${fieldNameRoot}.lookups`}
                items={attribute.lookups}
                label={attribute.name}
                setFieldValue={setFieldValue}
                error={objectPath.get(errors, `${fieldNameRoot}.lookups`)}
                singleCheckBox={false}
              ></Field>
            </div>
          );
        case opertationMode.DATE_OF_BIRTH:
          return (
            <Field
              component={DateInputDropdown}
              key={`${fieldNameRoot}.textValue`}
              name={`${fieldNameRoot}.textValue`}
              label={attribute.name}
              placeholder={attribute.name}
              setFieldValue={setFieldValue}
              error={objectPath.get(errors, `${fieldNameRoot}.textValue`)}
              autoComplete="off"
            />
          );
        case opertationMode.PHONE_NUMBER:
        case opertationMode.FULL_PHONE:
          return (
            <Field
              component={PhoneInput}
              key={`${fieldNameRoot}.textValue`}
              name={`${fieldNameRoot}.textValue`}
              label={attribute.name}
              placeholder={attribute.name}
              setFieldValue={setFieldValue}
              error={objectPath.get(errors, `${fieldNameRoot}.textValue`)}
            />
          );
        case opertationMode.EMAIL:
          return (
            <Field
              component={Input}
              key={`${fieldNameRoot}.textValue`}
              name={`${fieldNameRoot}.textValue`}
              label={attribute.name}
              placeholder={attribute.name}
              setFieldValue={setFieldValue}
              error={objectPath.get(errors, `${fieldNameRoot}.textValue`)}
            />
          );
        case opertationMode.COMMENTS:
          return (
            <Field
              component={Textarea}
              key={`${fieldNameRoot}.textValue`}
              name={`${fieldNameRoot}.textValue`}
              label={attribute.name}
              placeholder={attribute.name}
              onChange={(e) => {
                if (e.target.value.length > MAX_TEXT_LENGTH) {
                  e.target.value = e.target.value.substring(0, MAX_TEXT_LENGTH);
                  setFieldValue(`${fieldNameRoot}.textValue`, e.target.value);
                }
              }}
              setFieldValue={setFieldValue}
              error={objectPath.get(errors, `${fieldNameRoot}.textValue`)}
            />
          );
        case opertationMode.MEASUREMENT_HEIGHT:
          return renderMeasurementHeightInput(attribute, fieldNameRoot, errors);
        case opertationMode.MEASUREMENT_WEIGHT:
          return renderMeasurementWeightInput(attribute, fieldNameRoot, errors);
        case opertationMode.MEASUREMENT_SIMPLE:
          return renderMeasurementSimpleInput(attribute, fieldNameRoot, errors);
        case opertationMode.MEASUREMENT_AREA:
          return renderMeasurementAreaInput(attribute, fieldNameRoot, errors);
        case opertationMode.LOCATION:
          return (
            <Field
              component={Input}
              key={`${fieldNameRoot}.textValue`}
              name={`${fieldNameRoot}.textValue`}
              label={attribute.name}
              placeholder={attribute.name}
              setFieldValue={setFieldValue}
              error={objectPath.get(errors, `${fieldNameRoot}.textValue`)}
            />
          );
        case opertationMode.SEARCH_KEYWORD:
        case opertationMode.UPLOADED_DATE_RANGE:
        case opertationMode.EDITED_DATE_RANGE:
          break;
        default:
      }
    };

    const validationSchema = buildCastingFormValidationSchema(castingFormData.measuringSystem);

    return (
      <Formik
        innerRef={formik}
        validateOnChange={false}
        initialValues={{ ...castingFormData }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        enableReinitialize={false}
        validateOnMount={true}
      >
        {({ setFieldValue, values, errors }) => (
          <Form>
            <FormikEffect onChange={adjustUnloadHandle} />
            <div className="register-form">
              {values.groups.map((group, groupIndex) => {
                return (
                  <div key={`groups.${groupIndex}`} className="register-form-group">
                    {group.name && group.attributes && group.attributes.length && groupIndex !== 0 && (
                      <>
                        <hr />
                        <h2>{group.name}</h2>
                      </>
                    )}
                    {group.attributes.map((attribute, attributeIndex) => (
                      <div key={`groups.${groupIndex}.attributes.${attributeIndex}`}>
                        {renderInput(attribute, groupIndex, attributeIndex, setFieldValue, values, errors, items)}
                      </div>
                    ))}
                  </div>
                );
              })}
              <input type="hidden" name="emailDuplicationPermited" value={castingFormData.emailDuplicationPermited} />
            </div>
            <div className="register-recaptcha-container">
              <ReCaptcha onChange={reCaptchaChanged} fRef={recaptchaRef} />
            </div>

            {showEmailDuplicationMessage && (
              <div className="validation-summary-short">
                <ul>
                  <ErrorList name={castingFormData.duplicateEmailSubmitMessage} />
                </ul>
              </div>
            )}
            {showValidation && (
              <div className="validation-summary">
                <ul>
                  {items.map((item) => (
                    <ErrorList name={item} />
                  ))}
                </ul>
              </div>
            )}

            {renderEmailDuplicationPermitCheckbox()}
            <div className="register-upload-button">
              <Button
                type="submit"
                error={false}
                onClick={() => {
                  validationSchema.isValid(values).then((valid) => {
                    if (!valid) {
                      setShowValidation(true);
                    }
                  });
                }}
              >
                {staticText.uploadButton}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
);

export default CastingForm;
