import { _fetch } from 'utils/fetch';
import { GLOBAL_ERROR_MESSAGE, SESSION_HAS_ENDED_ERROR_MESSAGE } from 'utils/constants';
import * as httpStatus from 'utils/constants/httpStatus';

const API_URL = () => global.config[process.env.NODE_ENV].profileUpdateAttachmentProcessingUrl;

export const upload = async (apiKey, file, cropEnabled = true) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/upload/${cropEnabled}`, {
      method: 'POST',
      multipart: true,
      external: true,
      headers: { 'api-key': apiKey },
      body: {
        files: file
      }
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  if (!result) throw new Error(GLOBAL_ERROR_MESSAGE);

  return result;
};

export const addVideo = async (apiKey, videoUrl) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/upload/videos`, {
      method: 'POST',
      multipart: false,
      external: true,
      headers: { 'api-key': apiKey },
      body: videoUrl,
      json: false
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const updateVideo = async (apiKey, attachmentId, videoUrl) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/upload/videos/${attachmentId}`, {
      method: 'POST',
      multipart: false,
      external: true,
      headers: { 'api-key': apiKey },
      body: videoUrl,
      json: false
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const crop = async (apiKey, body) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/images/crop`, {
      method: 'POST',
      multipart: false,
      external: true,
      headers: { 'api-key': apiKey },
      body
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  if (!result) throw new Error(GLOBAL_ERROR_MESSAGE);

  return result;
};

export const sortOrder = async (apiKey, body) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/images/sortorder`, {
      method: 'POST',
      multipart: false,
      external: true,
      headers: { 'api-key': apiKey },
      body
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const setDefault = async (apiKey, attachmentId) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/images/${attachmentId}/defaultimage`, {
      method: 'POST',
      multipart: false,
      external: true,
      headers: { 'api-key': apiKey }
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

export const remove = async (apiKey, attachmentId) => {
  let response;
  try {
    response = await _fetch(`${API_URL()}/delete/${attachmentId}`, {
      method: 'DELETE',
      multipart: false,
      external: true,
      headers: { 'api-key': apiKey }
    });
  } catch (error) {
    throw new Error(GLOBAL_ERROR_MESSAGE);
  }

  const result = handleResponse(response);
  return result;
};

const handleResponse = (response) => {
  if (response?.status === httpStatus.UNAUTHORIZED) throw new Error(SESSION_HAS_ENDED_ERROR_MESSAGE);

  const error = response?.payload?.errorMessage;
  if (error) throw new Error(error);

  if (response?.status !== httpStatus.OK) throw new Error(GLOBAL_ERROR_MESSAGE);

  return response?.payload;
};
