import * as Yup from 'yup';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import * as opertationMode from 'utils/constants/operationMode';
import * as measuringSystem from 'utils/constants/measuringSystem';

import { FIELD_IS_REQUIRED, MAX_TEXT_LENGTH } from './consts';

const isTextInput = (controlType) =>
  [
    opertationMode.TEXT_BOX,
    opertationMode.DATE_OF_BIRTH,
    opertationMode.PHONE_NUMBER,
    opertationMode.FULL_PHONE,
    opertationMode.EMAIL,
    opertationMode.COMMENTS,
    opertationMode.LOCATION
  ].includes(controlType);

const lookupValidation = (isRequired, controlType) => {
  let validationSchema = Yup.array().nullable();

  if (![opertationMode.CHECK_BOX, opertationMode.CHECK_BOX_MULTIPLE].includes(controlType)) {
    return validationSchema;
  }

  validationSchema = validationSchema.test('lookup-validation', FIELD_IS_REQUIRED, function (value) {
    if (!isRequired) {
      return true;
    }
    if (!value && !value.length) {
      return false;
    }

    return value.some((item) => item.isSelected === true);
  });

  return validationSchema;
};

const buildValueValidation = (isRequired, controlType, validatePhoneNumber) => {
  let validationSchema = Yup.string().nullable().max(MAX_TEXT_LENGTH, 'Too long');

  if (!isTextInput(controlType)) {
    return validationSchema;
  }

  if (
    validatePhoneNumber &&
    (controlType === opertationMode.PHONE_NUMBER || controlType === opertationMode.FULL_PHONE)
  ) {
    validationSchema = validationSchema.test(
      'phone-validation',
      'This phone number doesn’t meet the standard',
      function (value) {
        if (!value) {
          return true;
        }

        if (value === 'error') {
          return false;
        }

        const phoneUtil = PhoneNumberUtil.getInstance();

        try {
          const parsedNumber = phoneUtil.parse(value);
          const formatedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.E164);
          return !!formatedNumber;
        } catch (ex) {
          return false;
        }
      }
    );
  }

  if (controlType === opertationMode.DATE_OF_BIRTH) {
    validationSchema = validationSchema.test('date-validation', 'Selected date is invalid', function (value) {
      if (value && value === 'error') {
        return false;
      }

      return true;
    });
  }

  if (isRequired) {
    validationSchema = validationSchema.required(' ');
  }

  return validationSchema;
};

export const buildCastingFormValidationSchema = (companyMeasuringSystem) => {
  return Yup.object().shape({
    groups: Yup.array().of(
      Yup.object().shape({
        attributes: Yup.array().of(
          Yup.object().shape({
            isPhotoUploaded: Yup.boolean().when(['isRequired', 'isPhotos'], (isRequired, isPhotos) => {
              return isRequired && isPhotos
                ? Yup.boolean().nullable().required(FIELD_IS_REQUIRED)
                : Yup.boolean().nullable();
            }),
            isVideoUploaded: Yup.boolean().when(['isRequired', 'isVideo'], (isRequired, isVideo) => {
              return isRequired && isVideo
                ? Yup.boolean().nullable().required(FIELD_IS_REQUIRED)
                : Yup.boolean().nullable();
            }),
            textValue: Yup.string().when(['isRequired', 'controlType'], (isRequired, controlType) =>
              buildValueValidation(isRequired, controlType, false)
            ),
            selectedItemId: Yup.string().when(['isRequired', 'controlType'], (isRequired, controlType) => {
              return isRequired &&
                (controlType === opertationMode.DROP_DOWN || controlType === opertationMode.DROP_DOWN_MULTIPLE)
                ? Yup.string().nullable().required(FIELD_IS_REQUIRED)
                : Yup.string().nullable();
            }),
            lookups: Yup.array().when(['isRequired', 'controlType'], lookupValidation),
            measurementSimple: Yup.object()
              .nullable()
              .when(['isRequired', 'controlType'], (isRequired, controlType) =>
                isRequired && controlType === opertationMode.MEASUREMENT_SIMPLE
                  ? Yup.object()
                      .shape({
                        value: Yup.number().required(FIELD_IS_REQUIRED)
                      })
                      .required(FIELD_IS_REQUIRED)
                  : Yup.object().nullable()
              ),
            measurementHeight: Yup.object()
              .nullable()
              .when(['isRequired', 'controlType'], (isRequired, controlType) =>
                isRequired && controlType === opertationMode.MEASUREMENT_HEIGHT
                  ? Yup.object()
                      .shape(
                        companyMeasuringSystem === measuringSystem.IMPERIAL
                          ? {
                              foot: Yup.number().positive().required(FIELD_IS_REQUIRED),
                              inches: Yup.number().required(FIELD_IS_REQUIRED)
                            }
                          : {
                              cm: Yup.number().positive().required(FIELD_IS_REQUIRED)
                            }
                      )
                      .required(FIELD_IS_REQUIRED)
                  : Yup.string().nullable()
              ),
            measurementWeight: Yup.object()
              .nullable()
              .when(['isRequired', 'controlType'], (isRequired, controlType) =>
                isRequired && controlType === opertationMode.MEASUREMENT_WEIGHT
                  ? Yup.object()
                      .shape(
                        companyMeasuringSystem === measuringSystem.IMPERIAL
                          ? {
                              lb: Yup.number().positive().required(FIELD_IS_REQUIRED)
                            }
                          : {
                              kg: Yup.number().positive().required(FIELD_IS_REQUIRED)
                            }
                      )
                      .required(FIELD_IS_REQUIRED)
                  : Yup.object().nullable()
              ),
            measurementArea: Yup.object()
              .nullable()
              .when(['isRequired', 'controlType'], (isRequired, controlType) =>
                isRequired && controlType === opertationMode.MEASUREMENT_AREA
                  ? Yup.string().nullable().required(FIELD_IS_REQUIRED)
                  : Yup.string().nullable()
              )
          })
        )
      })
    )
  });
};
