import React from 'react';
import PropTypes from 'prop-types';
import { textWithClickableLink } from 'utils/htmlToLink';
import {useParams} from 'react-router-dom';

import Panel from 'components/Panel';

import './RegistrationSuccess.scss';

const RegistrationSuccess = ({ message, profileUpdate, children }) => {
  const { embed } = useParams()
  
  return (
    <div className="registration-success">
      <Panel noBorder={profileUpdate ? true : embed === 'embed'}>
        <div
          className={"server-html"}
          dangerouslySetInnerHTML={children ? undefined : {
            __html: textWithClickableLink(message)
          }}
        />
        {children}
      </Panel>
    </div>
  );
};

RegistrationSuccess.propTypes = {
  message: PropTypes.any,
  buttonText: PropTypes.string,
  buttonCallback: PropTypes.func
};

export default RegistrationSuccess;
